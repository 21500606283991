<template>
  <ul class="menu-nav">
    <router-link
      :to="{ name: 'home' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-user-alt"></i>
          <span class="menu-text">{{ $t("pages.accounts") }}</span>
        </a>
      </li>
    </router-link>
    <router-link
      :to="{ name: 'reservations' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon svg-icon-xl svg-icon-success mr-3">
            <!--begin::Svg Icon | path:assets/media/svg/icons/General/Settings-1.svg-->
            <inline-svg
              src="media/svg/icons/Home/Timer.svg"
              width="150"
              height="150"
              class="menu-icon"
            />
            <!--end::Svg Icon-->
          </span>
          <!-- <img src="" class="menu-icon" alt=""> -->

          <!-- <i class="menu-icon fas fa-user-alt"></i> -->
          <span class="menu-text">{{ $t("pages.reservations_enter_classroom") }}</span>
        </a>
      </li>
    </router-link>
    <router-link
      :to="{ name: 'reservations-activities' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon svg-icon-xl svg-icon-success mr-3">
            <!--begin::Svg Icon | path:assets/media/svg/icons/General/Settings-1.svg-->
            <inline-svg
              src="media/svg/icons/Devices/Gamepad1.svg"
              width="150"
              height="150"
              class="menu-icon"
            />
            <!--end::Svg Icon-->
          </span>
          <!-- <img src="" class="menu-icon" alt=""> -->

          <!-- <i class="menu-icon fas fa-user-alt"></i> -->
          <span class="menu-text">حجوزات النشاطات</span>
        </a>
      </li>
    </router-link>
    <router-link
      :to="{ name: 'reservations-birthday' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon svg-icon-xl svg-icon-success mr-3">
            <!--begin::Svg Icon | path:assets/media/svg/icons/General/Settings-1.svg-->
            <inline-svg
              src="media/svg/icons/Food/Cake.svg"
              width="150"
              height="150"
              class="menu-icon"
            />
            <!--end::Svg Icon-->
          </span>
          <!-- <img src="" class="menu-icon" alt=""> -->

          <!-- <i class="menu-icon fas fa-user-alt"></i> -->
          <span class="menu-text">حجوزات اعياد الميلاد</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
