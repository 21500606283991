var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":{ name: 'home' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-user-alt"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("pages.accounts")))])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'reservations' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon svg-icon-xl svg-icon-success mr-3"},[_c('inline-svg',{staticClass:"menu-icon",attrs:{"src":"media/svg/icons/Home/Timer.svg","width":"150","height":"150"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("pages.reservations_enter_classroom")))])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'reservations-activities' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon svg-icon-xl svg-icon-success mr-3"},[_c('inline-svg',{staticClass:"menu-icon",attrs:{"src":"media/svg/icons/Devices/Gamepad1.svg","width":"150","height":"150"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("حجوزات النشاطات")])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'reservations-birthday' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon svg-icon-xl svg-icon-success mr-3"},[_c('inline-svg',{staticClass:"menu-icon",attrs:{"src":"media/svg/icons/Food/Cake.svg","width":"150","height":"150"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("حجوزات اعياد الميلاد")])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }